<template>
  <div class="information-account">
    <div class="header">Thông tin tài khoản</div>
    <el-row :gutter="24">
      <el-col :lg="8" class="text-center">
        <el-upload
          v-loading="loading"
          class="avatar-uploader"
          list-type="image"
          accept="image/*"
          action
          :show-file-list="false"
          :http-request="uploadAvatar"
        >
          <el-avatar
            :size="150"
            :src="formInfo.avatar_url ? formInfo.avatar_url : ''"
            class="avatar"
          ></el-avatar>
          <div class="hover-upload">
            <i class="el-icon-camera-solid"></i>
          </div>
        </el-upload>
      </el-col>
      <el-col :lg="16">
        <el-card shadow="always" class="w-75">
          <el-form ref="form" :model="formInfo">
            <el-form-item label="Tên tài khoản" prop="name">
              <el-input v-model="formInfo.name"></el-input>
            </el-form-item>
            <el-form-item label="Địa chỉ Email" prop="email">
              <el-input v-model="formInfo.email" disabled></el-input>
            </el-form-item>
            <el-form-item label="Số điện thoại" prop="phone">
              <el-input v-model="formInfo.phone"></el-input>
            </el-form-item>
            <el-form-item label="Địa chỉ liên hệ" prop="address">
              <el-input v-model="formInfo.address" type="textarea"></el-input>
            </el-form-item>
            <el-row type="flex" justify="end">
              <el-button
                type="primary"
                :loading="callingAPI || loading"
                @click="handleUpdateInfo"
              >Cập nhật thông tin</el-button>
            </el-row>
          </el-form>
        </el-card>
        <!-- <el-card shadow="always" class="mt-4 w-75">
          <el-form v-if="isUpdatePassword" ref="formPassword" :model="formPassword">
            <el-form-item label="Mật khẩu cũ" prop="password">
              <el-input v-model="formPassword.password" type="password" placeholder="Nhập mật khẩu" show-password></el-input>
            </el-form-item>
            <el-form-item label="Mật khẩu mới" prop="newPassword">
              <el-input v-model="formPassword.newPassword" type="password" placeholder="Nhập mật khẩu mới" show-password></el-input>
            </el-form-item>
            <el-form-item label="Nhập lại mật khẩu mới" prop="confirmPassword">
              <el-input v-model="formPassword.confirmPassword" type="password" show-password></el-input>
            </el-form-item>
          </el-form>
          <el-row type="flex" justify="end">
            <el-button v-if="isUpdatePassword" type="danger" @click="handleCancelUpdatePassword">Hủy</el-button>
            <el-button type="primary" @click="handleUpdatePassword">{{ isUpdatePassword ? 'Lưu' : 'Cập nhật mật khẩu' }}</el-button>
          </el-row>
        </el-card>
      </el-col> -->
      </el-col></el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { loginInfo, logout } from '@/services/auth'
import dayjs from 'dayjs'
import { createStorage } from '@/services/storage'
import { updateUser } from '@/services/user'
export default {
  name: 'InformationAccount',
  data() {
    return {
      loading: false,
      callingAPI: false,
      formInfo: {},
      formPassword: {
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        name: [
          { required: true, message: 'Tên tài khoản không được để trống', trigger: ['blur', 'change'] }
        ]
      },
      isUpdatePassword: false
    }
  },
  computed: {
    accountInfo() {
      return loginInfo().auth_user
    },
    ...mapGetters(['language'])
  },
  beforeMount() {
    this.formInfo = { ...loginInfo().auth_user }
  },
  methods: {
    uploadAvatar(fileInfo) {
      const params = {
        file: fileInfo.file,
        objectId: this.formInfo.id,
        objectType: 'user',
        fileType: 'image',
        locale: this.language
      }
      this.loading = true
      createStorage(params).then((res) => {
        this.formInfo.avatar_url = res.data.url
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.$notify.error({
          title: 'Thông báo',
          message: 'Không thể upload avatar: ' + fileInfo.file.name
        })
      })
    },
    handleUpdateInfo() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          var slugify = require('slugify')
          this.callingAPI = true
          const data = {
            ... this.formInfo,
            deleted: false,
            slug: slugify(this.formInfo.name, '-'),
            updated_at: dayjs().format('DD/MM/YYYY')
          }
          updateUser(data).then(() => {
            this.callingAPI = false
            this.$notify.success({
              title: 'Thành công',
              message: 'Cập nhật thành công'
            })
            logout()
          }).catch(() => {
            this.$notify.error({
              title: 'Thất bại',
              message: 'Cập nhật thất bại'
            })
            this.callingAPI = false
          })
        }
      })
    },

    handleUpdatePassword() {
      this.isUpdatePassword = true
    },
    handleCancelUpdatePassword() {
      this.$refs.formPassword.resetFields()
      this.isUpdatePassword = false
    },
    handleCancelUpdate() {
      this.$refs.form.resetFields()
      this.isEditing = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  background-color: #f5f5f5;
  padding: 20px;
  color: #409eff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 15px;
}
table {
  td {
    padding: 10px 0;
  }
}
.avatar-uploader {
  height: 150px;
  width: 150px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:hover {
    .hover-upload {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }
  .avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    z-index: 1;
  }
  .hover-upload {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #00000047;
    z-index: 999;
    transition: all 0.5s ease-in-out;
    i {
      color: #333;
      font-size: 50px;
    }
  }
}
</style>
